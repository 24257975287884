<!-- Cierres de Expedientes -->

<template>
  <div class="exp_Cierres">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">
        <!-- v-on:click="btra_Mto({ accion:0 })" -->
        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          v-on='on'
          v-on:click="get_datos()"
          v-bind="$cfg.btra_cfg.standard"
          :disabled="disable"
          title="Cierres">
            <v-icon>{{'mdi-lock-outline'}}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Controles del Mto -->
          <div v-if="record" class="contenedor" style="width:750px">
            
            <!-- Botonera -->
            <div class="conflex">
              <v-btn               
                @click="guardar()"
                :disabled="!editado"  
                v-bind="$cfg.btra_cfg.standard"
                title="Guardar Observaciones">
                  <v-icon>{{'mdi-content-save'}}</v-icon>
              </v-btn>

              <v-btn                 
                style="margin-left:10px"
                @click="get_datos"
                :disabled="!editado"
                v-bind="$cfg.btra_cfg.standard"
                title="Cancelar">
                  <v-icon>{{'mdi-close'}}</v-icon>
              </v-btn> 
            </div>
            
            <!-- Controles -->
            <div>
              <div class="conflex" style="padding-top:10px"> 
                <div class="columna" style="width:55%">
                  
                  <div class="cab">Expediente<noscript></noscript></div>
                  <v-sheet v-bind="$cfg.styles.marco"> 
                    <div class="conflex">
                      <div class="columna" style="width:40%">
                        <v-text-field
                          v-bind="$input"
                          v-model="record.control"
                          label="Control"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="columna" style="width:60%">
                        <v-text-field
                          v-bind="$input"
                          v-model="record.callref1"
                          label="Referencia"
                          disabled
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="columna" style="width:100%">
                        <v-text-field
                          v-bind="$input"
                          v-model="record.fallename"
                          label="Fallecido"
                          disabled
                        ></v-text-field>
                    </div>
                  </v-sheet>
                
                </div>
                    
                <div class="columna" style="width:45%">                
                  <div class="cab" style="width:90px">Cierres</div>
                  <v-sheet v-bind="$cfg.styles.marco"> 
                    
                    <div class="conflex" style="align-items:center">                   
                      <v-checkbox
                        style="width:30%"
                        v-bind="$checkbox"
                        v-model="schema.ctrls.chk1.value"                            
                        :label="schema.ctrls.chk1.label"
                        :disabled="schema.ctrls.chk2.value=='1' || editado || (schema.ctrls.chk1.value=='1' && ndep==1)"
                        dense
                        @change="showCierreApertura(1)">
                      </v-checkbox>
                      <!--deshabilitado si admon cerrado ó si hay observaciones en edicion (editado)
                          ó apertura para operadoras(1)
                      -->
                                        
                      <div class="conflex" style="width:70%">
                        <v-icon :style="schema.ctrls.chk1.value=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                        <span style="padding-left:10px">{{ schema.ctrls.chk1m.value }}</span>                        
                      </div>
                    </div>

                    <div class="conflex" style="align-items:center">                   
                      <v-checkbox
                        style="width:30%"
                        v-bind="$checkbox"
                        v-model="schema.ctrls.chk2.value"
                        :label="schema.ctrls.chk2.label"                       
                        :disabled="schema.ctrls.chk1.value=='0' || schema.ctrls.chk3.value=='1' || editado || ndep<3 "
                        dense
                        @change="showCierreApertura(2)">
                      </v-checkbox>
                      <!--deshabilitado si ctd no cerrado ó cerrado completo ó si hay observaciones en edicion (editado)
                          ó para usuarios menor que supervisoras(3)                          
                      -->
                    
                      <div class="conflex" style="width:70%">
                        <v-icon :style="schema.ctrls.chk2.value=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                        <span style="padding-left:10px">{{ schema.ctrls.chk2m.value }}</span>                       
                      </div>
                    </div>

                    <div class="conflex" style="align-items:center">                   
                      <v-checkbox
                        style="width:30%"
                        v-bind="$checkbox"
                        v-model="schema.ctrls.chk3.value"
                        :label="schema.ctrls.chk3.label"     
                        :disabled="schema.ctrls.chk2.value=='0' || editado || (ndep<3 && schema.ctrls.chk3.value=='1') || (ndep<6 && schema.ctrls.chk3.value=='0')"
                        dense
                        @change="showCierreApertura(3)">
                      </v-checkbox>
                      <!--deshabilitado si admon no cerrado ó si hay observaciones en edicion (editado)
                          ó apertura para usuarios menor que supervisoras(3)
                          ó cierre para usuarios menor que departamental(6)
                      -->
                    
                      <div class="conflex" style="width:70%">
                        <v-icon :style="schema.ctrls.chk3.value=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                        <span style="padding-left:10px">{{ schema.ctrls.chk3m.value }}</span>                       
                      </div>
                    </div>
                                    
                  </v-sheet>  
                </div>
              </div>

              <div class="cab">Por cumplimentar para cierre</div>
              <v-sheet v-bind="$cfg.styles.marco"> 
                <div v-if="!chkPulsado">
                  <ctrlobs3
                    :schemaComponente="schema.ctrls.chkdef"
                    :schema="schema"
                    :record="record"
                    :edicion="schema.ctrls.chk3.value=='0'">
                  </ctrlobs3>
                </div>

                <div v-else>       
                  <h2 style="color:red">{{ titulo }}</h2>

                  <v-select
                    v-bind="$select"
                    v-model="schema.ctrls.motivo.value"                        
                    :label="schema.ctrls.motivo.label"
                    :items="itemsCierreApertura"
                    dense
                    item-value="name"
                    item-text="name">
                  </v-select>
      
                  <v-textarea
                    v-bind="$textarea"
                    v-model="schema.ctrls.comentario.value"
                    :label="schema.ctrls.comentario.label"
                    rows="3"
                    no-resize>                      
                  </v-textarea>
                </div>
              </v-sheet> 
            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
    
  export default {
    mixins: [mixinMto, mixinMtoArray],
    components: { base_Header, ctrlobs3 },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: '' },
      tip: { type:Number, default: 1},
      id:  { type:String, default: ''},            
      show: { type: [String, Boolean], default: "1" },  
      disable: { type: Boolean, default: false },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            chk1: { f:'chk1', label:'CTD', value:"0"},
            chk2: { f:'chk2', label:'ADMON', value:"0"},
            chk3: { f:'chk3', label:'CIERRE', value:"0"},            
            chk1f: { f:'chk1f', label:'Conf. CTD', value:"0"},
            chk2f: { f:'chk2f', label:'Conf. ADMON', value:"0"},
            chk3f: { f:'chk3f', label:'CIERRE', value:"0"},
            chk1u: { f:'chk1u', label:'Conf. CTD', value:"0"},
            chk2u: { f:'chk2u', label:'Conf. ADMON', value:"0"},            
            chk3u: { f:'chk3u', label:'CIERRE', value:"0"},
            chk1m: { f:'chk1m', label:'Conf. CTD', value:"0"},
            chk2m: { f:'chk2m', label:'Conf. ADMON', value:"0"},
            chk3m: { f:'chk3m', label:'CIERRE', value:"0"},
            chkdef: { f:'', label:'', value:"",
              comp: { 
                type:"obs3",
                fields:{ gestion:"chkdef" },
                valueaux:""                            
              }
            },            
                        
            digf_rec: { f:'digf_rec', label:'Recepción', value:"", ini:'Ordinario'},
            digf_obs: { f:'digf_obs', label:'Observaciones', value:""},
            motivo:   { f:'motivo', label:'Motivo', value:"" },
            comentario: { f:'comentario', label:'Comentario', value:"" }
          }
        },
        api:'',
        stName:'stMexp_Cierres',

        dialog:false,
        itemsRecepcion: [],
        itemsCierreApertura:[],
        chkPulsado:0,
        titulo:''
      };
    },


    created() {
      this.ini_data();
    },


    computed: {

      chk2() {
        return (this.schema.ctrls.chk2.value=='1');
      },

      ctb() {
        return (this.record.ctb=='1');
      },

      editado() {
        return (this.schema.ctrls.chkdef.comp.valueaux>'' || this.chkPulsado>0);
      }

    },   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "CIERRES - Expediente"; 
      
        // items adicionales
        this.itemsRecepcion= [
          { id:"0", name:"Ordinario"},
          { id:"1", name:"Certificado"},
          { id:"2", name:"Mensajeria"},
          { id:"3", name:"Otros"},
          { id:"4", name:"Firma Digital"},
        ];

      },


      // al terminar de cargar el registro, si la factura aún no ha sido
      // digitalizada, inicializo schema
      record_cargado() {
        if (this.contabilizada) return;
        this.record2ctrl({}, this.schema.ctrls, true);
      },


      // obtengo datos del cierre
      async get_datos() {     
        // inicializo datos
        this.itemsCierreApertura= [];
        this.chkPulsado=0;

        // llamada API
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'cierre',
          fnt:'get',
          id: this.id
        }};
        console.log('args get_datos: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.record2ctrl(apiResult.r[0], this.schema.ctrls); 
        this.$store.commit(this.stName + '/data2State', { prop:'record', value:apiResult.r[0] }); 
      },


      // muestro opciones de Cierre / Apertura
      showCierreApertura(chk) {

        //let V=(this.schema.ctrls['chk' + chk].value=='0' ? '1' : '0');
        let V=Number(this.schema.ctrls['chk' + chk].value);

        console.log('*** showCierreApertura ', chk, V, this.ctb);

        //cierre ctd si validamos
        if (chk==1 && V==1 ) {
          if (!this.validarCierreCTD()) {          
            //this.$root.$alert.open('<br> IMPOSIBLE CERRAR CTD<br>', 'error');   
            //this.schema.ctrls.chk1.value='0';
            this.get_datos(); 
            return;
          }          
        }        

        //cierre admon sino contabilizada
        if (chk==2 && V==1 && !this.ctb) {          
          this.$root.$alert.open('<br>IMPOSIBLE CIERRE ADMON<hr><br><b>EXISTEN FACTURAS SIN CONTABILIZAR</b><br>', 'error');   
          //leemos de nuevo          
          this.get_datos(); 
          return;
        }

        this.itemsCierreApertura= (V==1 ? this.$store.state.datos_iniciales.motivos_cierre : this.$store.state.datos_iniciales.motivos_reapertura);
        
        this.chkPulsado=chk;
        this.titulo= (V==1? "CIERRE " : "APERTURA ") + (chk==1? "CTD" : chk==2? "ADMON" : "") + " EXPEDIENTE";
      },
     

      // guardar observaciones / Cierre
      guardar() {        
        if (!this.chkPulsado) return this.guardarObs();
        this.guardarCierre();
      },


      // guardar Observaciones
      guardarObs() {
        if (!this.schema.ctrls.chkdef.comp.valueaux) {
          this.$root.$alert.open('Debe al menos introducir una observación', 'error');
          return;
        }

        this.guardarObs_continue();
      },

      async guardarObs_continue() {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'cierre',
          fnt:'obs',
          id: this.id,
          txt: this.schema.ctrls.chkdef.comp.valueaux
        }};
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.get_datos();
      },


      // guardar Cierre
      async guardarCierre() {
         if (!this.schema.ctrls.motivo.value) {
          this.$root.$alert.open('Debe introducir un MOTIVO', 'error');
          return;
        }

        let val= this.schema.ctrls['chk' + this.chkPulsado].value;
        
        await this.$root.$alert.open('Deseas ' + (val=="1"? "ACEPTAR":"CANCELAR") + ' el cierre ' + (this.chkPulsado==1? "CTD":this.chkPulsado==2? "ADMON": "") + " del expediente?", "confirm")
          .then(r=> { if (r) { this.cierreAPI(); }          
        });
      },

      validarCierreCTD() {        
        let msg='';
        var F=this.record;         
        //console.log('********************',F);

        //si bloqueado por Jefe de zona (chkbloq)
        if (F.chkbloq=='1') {
          msg+="* BLOQUEADO POR COORDINADOR DE SERVICIO<br>";
        }            

        //si prestamos apd y no es renuncia comprobamos conformidad funeraria por cada apd
        if (F.chkrenuncia=='0' && F.p_apd=='1') {
            if (F.apd1>'0' && F.chkfun1=='') msg+="* FALTA CONFORMIDAD FUNERARIA (1)<br>";
            if (F.apd2>'0' && F.chkfun2=='') msg+="* FALTA CONFORMIDAD FUNERARIA (2)<br>";
        }
        
        //insuf.capital
        if (F.chkcapital=='1' && F.chkautoriza=='0' && F.chkautoriza_no=='0') 
            msg+="* INSUFICIENCIA DE CAPITAL SIN AUTORIZAR<br>";
        
        //cs aplicado
        if (F.cs_aplica=='1' && F.cs_aplicajz=='0') 
            msg+="* CS SIN AUTORIZAR POR COORDINADOR DE SERVICIO<br>";   

        //fin de compras y que haya facturas (excepcion mapfre asistencia)            
        if (F.callemp!='84' && F.chkpresapd=='0' && F.fras>'0') {
          msg+="* FIN DE COMPRAS NO MARCADO<br>";  
        }              
            
        //que tenga algun traslado ó renuncia/ocultacion
        if (F.tras=='0') {
          msg+="* DEBE HABER AL MENOS UNA LINEA DE TRASLADOS Ó (RENUNCIA/OCULTACIÓN)<br>";  
        }
        
        ///////////////////////////////
        //mostramos ERRORES producidos
        if (msg) {
          this.$root.$alert.open("IMPOSIBLE CIERRE CTD<hr><br>" + msg, 'error');          
        }        
        return (msg=='');

      },

      
      async cierreAPI() {       
        let args = { tipo:'fnc', accion: 'expediente', 
          fn_args: { 
            accion:'cierre',
            fnt:'set',
            id: this.id,
            tip:this.chkPulsado,
            txt:this.schema.ctrls.comentario.value,
            chk:this.schema.ctrls['chk' + this.chkPulsado].value=='1'? 1 : 0,
            mot:this.schema.ctrls.motivo.value
          }
        }
                
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        
        //salimos
        this.dialog= false;
        this.$emit('cierreGuardado');
      },

    }
  }
</script>
